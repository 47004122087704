<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">文件标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button @click="postdata(scope.row)" type="success" size="mini">选择交办人</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

		<!--审核弹出-->
		<el-dialog title="选择交办人" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="选择交办人" required>
						<div style="display: flex;align-items: center;">
							<el-button type="primary" size="small" @click="select">选择</el-button>
						</div>
						<div>
							<span style="color: #999;">已选人员：</span>{{user_name}}
						</div>
					</el-form-item>
					<!-- <el-form-item label="电子签名" required>
						<div style="width: 100%;padding: 40px;">
							<vue-esign style="border: 1px solid #ddd;" ref="esign" :isClearBgColor="isClearBgColor" />
							<div
								style="width: 100%;display: flex;align-items: center;justify-content: space-around;margin-top: 10px;">
								<el-button type="primary" @click="reset" size="mini">重置签名</el-button>
							</div>
						</div>

					</el-form-item> -->
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">通 过</el-button>
			</span>
		</el-dialog>



		<detail ref="mydetail"></detail>
		<selectusers ref="myselectuser" @selectedry="selectedry"></selectusers>
	</div>
</template>

<script>
	import axios from 'axios';
	import detail from '../components/detail.vue'
	import selectusers from '@/components/select_users.vue'
	export default {
		components: {
			detail,
			selectusers
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '文件标题',
						width: ''
					},
					{
						prop: 'fhuser_name',
						name: '复核人',
						width: '120'
					},
					{
						prop: 'qfuser_name',
						name: '签发人',
						width: '120'
					},
					{
						prop: 'jjcd',
						name: '加急程度',
						width: '120',
						type: 'jjcd'
					},
					{
						prop: 'addtime',
						name: '提交时间',
						width: '150'
					}
				],
				tableData: [],
				showtc: false,
				sheng: {
					id: '',
					zsuser_id: ''
				},
				selet_type: 0,
				user_name: '',
				user_id: '',

				//签字相关
				canWidth: 800, //画布宽度--是不会超出父元素的宽度的--设置也不行
				canHeight: 300,
				lineWidth: 3, //画笔粗细
				lineColor: '#000000', //画笔颜色
				bgColor: '#ffffff', //画布背景
				isCrop: false, //是否裁剪
				isClearBgColor: true, //是否清空背景色
				resultImg: '', //生成签名图片-base64
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			//签名版相关
			dataURItoBlob(dataURI) {
				const byteString = atob(dataURI.split(',')[1]);
				const ab = new ArrayBuffer(byteString.length);
				const ia = new Uint8Array(ab);
				for (let i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}
				return new Blob([ab], {
					type: 'image/png'
				});
			},
			queren() {
				if (!this.sheng.zsuser_id) {
					this.$message.warning('请选择交办人')
					return
				}
				this.$post({
					url: '/api/matterfwen/tjbanli',
					params: this.sheng
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setsheng()
				})
				// this.$refs.esign.generate().then(res => { //使用generate将签名导出为图片
				// 	const blob = this.dataURItoBlob(res);
				// 	const formData = new FormData();
				// 	formData.append('file', blob, 'qm.png');
				// 	axios.post(this.$URL + '/api/login/upload', formData, {
				// 			headers: {
				// 				'Content-Type': 'multipart/form-data',
				// 				'token': this.$store.state.userinfo.token
				// 			}
				// 		})
				// 		.then(response => {
				// 			// 请求成功的处理
				// 			console.log('图片上传成功', response.data);
				// 			this.sheng.qm = response.data.id
				// 			this.$post({
				// 				url: '/api/matterfwen/tjbanli',
				// 				params: this.sheng
				// 			}).then((res) => {
				// 				this.$message({
				// 					type: 'success',
				// 					message: '操作成功!'
				// 				})
				// 				this.gettable()
				// 				this.setsheng()
				// 			})
				// 		})
				// 		.catch(error => {
				// 			// 请求失败的处理
				// 			console.error('图片上传失败', error);
				// 		})
				// }).catch(err => {
				// 	this.$message.warning("请先完成签字!")
				// })

				
			},
			reset() {
				this.$refs.esign.reset() //清空画布内容
				this.lineWidth = 3
				this.lineColor = '#000000'
				this.bgColor = '#ffffff'
				this.isCrop = false
				this.resultImg = ''
			},

			setsheng() {
				this.sheng = {
					id: '',
					nbyj: '',
					psuser_id: '',
					csr: ''
				}
				this.showtc = false
			},
			select() {
				let cxidlist = this.user_id ? this.user_id.split(',') : []
				let cxnamelist = this.user_name ? this.user_name.split(',') : []
				this.$refs.myselectuser.isdanxuan = false
				let list = []
				if (cxidlist.length > 0) {
					cxidlist.forEach((item, index) => {
						let obj = {
							name: cxnamelist[index],
							id: Number(item)
						}
						list.push(obj)
					})
				}
				this.$refs.myselectuser.selectuser = list.length > 0 ? list : []
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e) {
				this.user_id = e.map((item) => {
					return item.id
				})
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item) => {
					return item.name
				})
				this.user_name = this.user_name.join(',')

				this.sheng.zsuser_id = this.user_id
			},
			showdetail(row) {
				this.$post({
					url: '/api/matterfwen/details',
					params: {
						id: row.id
					}
				}).then((res) => {
					this.$refs.mydetail.info = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			postdata(row) {
				this.user_name = row.zsuser_name
				this.user_id = row.zsuser_id
				this.sheng.zsuser_id = row.zsuser_id
				this.showtc = true
				this.sheng.id = row.id
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/matterfwen/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						type: 6
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'daijiaoban.scss';
</style>